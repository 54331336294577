import React from 'react';
import { injectIntl } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './HomeSlider.css';
import cx from 'classnames';
import Swiper from 'react-id-swiper';
import HomeItem from '../HomeItem';
import Loader from '../../Common/Loader';
import { isRTL } from '../../../helpers/formatLocale';
//Image
import nextIcon from '../../../../public/Icons/homeSliderNext.png';
import prevIcon from '../../../../public/Icons/homeSliderPrev.png';

const nextArrowStyle = {
  position: 'absolute',
  right: '-25px',
  background: 'transparent',
  color: '#00B0CD',
  zIndex: '2',
  width: '52px',
  height: '52px',
  top: '38%',
  fontSize: '40px',
  cursor: 'pointer',
  borderRadius: '50%',
  textAlign: 'center',
};

const prevArrowStyle = {
  position: 'absolute',
  left: '-25px',
  background: 'transparent',
  color: '#00B0CD',
  zIndex: '2',
  width: '52px',
  height: '52px',
  top: '38%',
  fontSize: '40px',
  cursor: 'pointer',
  borderRadius: '50%',
  textAlign: 'center',
};

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={nextArrowStyle}
      onClick={onClick}
    >
      <img src={nextIcon} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={prevArrowStyle}
      onClick={onClick}
    >
      <img src={prevIcon} />
    </div>
  );
}
class HomeSlider extends React.Component {

  static defaultProps = {
    data: []
  };

  constructor(props) {
    super(props);
    this.swiper = null;
    this.goNext = this.goNext.bind(this);
    this.goPrev = this.goPrev.bind(this);
    this.progress = this.progress.bind(this);
    this.state = {
      isBeginning: true,
      isEnd: false,
      load: false,
      isClient: false,
    };
  }

  componentDidMount() {
    const { data, fromPage } = this.props;
    this.setState({
      isClient: true,
      load: true
    });
  }

  goNext() {
    if (!this.swiper) return;
    this.swiper.slideNext();
  }

  goPrev() {
    if (!this.swiper) return;
    this.swiper.slidePrev();
  }

  progress() {
    if (!this.swiper) return;
    if (this.swiper.isEnd) {
      this.setState({ isEnd: true });
    } else if (this.swiper.isBeginning) {
      this.setState({ isBeginning: true });
    } else {
      this.setState({ isEnd: false, isBeginning: false });
    }
  }

  UNSAFE_componentWillReceiveProps(prevProps) {
    const { locale } = this.props.intl;
    const { locale: prevLocale } = prevProps.intl;
    if (locale !== prevLocale) {
      this.setState({
        load: false
      });
      clearTimeout(this.loadSync);
      this.loadSync = null;
      this.loadSync = setTimeout(() => {
        this.setState({
          load: true
        })
      }, 1);
    }
  }

  render() {
    const { data, intl: { locale } } = this.props;
    const { load } = this.state;
    const { isClient } = this.state;
    let showArrow = false;

    const params = {
      slidesPerView: 4,
      spaceBetween: 30,
      breakpoints: {
        1024: {
          slidesPerView: 3,
        },
        640: {
          slidesPerView: 'auto',
        }
      },
    };

    if (data) {
      if (data.result.length > 4) showArrow = true;
      else if (typeof window !== 'undefined') {
        if ((window.matchMedia('(max-width: 768px) and (min-width: 640px)').matches && data.result.length > 3)
          || (window.matchMedia('(max-width: 640px)').matches && data.result.length > 1)) {
          showArrow = true;
        }
      }
    }

    if (showArrow === true) {
      params.loop = true
    }
    
    return (
      <div className={s.root}>
        <div className={cx(s.paddingTopBottom, 'homeSlickSlider', s.sliderMain)}>

          {
            !load && <div>
              <Loader type="text" />
            </div>
          }
          {
            load && isClient && data &&
            data.result &&
            data.result.length > 0 &&
            <>
              <Swiper {...params} rtl={isRTL(locale)} className={cx('row', s.noMargin)} getSwiper={(swiper) => this.swiper = swiper}>
                {data.result.map((item, index) => {
                  return (
                    <div key={index}>
                      <div className='swiperSliderMobielWidth'>
                        <HomeItem
                          key={index}
                          categoryName={item.categoryName}
                          categoryImage={item.categoryImage}
                        />
                      </div>
                    </div>
                  );
                })}
              </Swiper>
              {
                showArrow &&
                <div>
                  <SamplePrevArrow
                    className={cx(s.displayNone, 'arrowPrevRTL')}
                    onClick={this.goPrev}
                  />
                  <SampleNextArrow
                    className={cx(s.displayNone, 'arrowNextRTL')}
                    onClick={this.goNext}
                  />
                </div>
              }
            </>
          }
        </div>
      </div >
    );
  }
}

export default injectIntl(withStyles(s)(HomeSlider))

