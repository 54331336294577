import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './AboutGrid.css';
import cx from 'classnames';
import { Container } from 'react-bootstrap';
import HomeContext from '../../../routes/context/homeContext'
import { api, homepageUploadDir } from '../../../config'

//Image
import bgOne from '../../../../public/static/aboutBgOne.svg';


class AboutGrid extends React.Component {
  render() {
    const homepage = this.context;
    const apiEndpoint = api && api.apiEndpoint;

    let aboutData = [];
    aboutData.push(
      {
        image: apiEndpoint + homepageUploadDir + homepage.aboutGridImage1,
        title: homepage.aboutGridTitle2,
        content: homepage.aboutGridContent2,
      },
      {
        image: apiEndpoint + homepageUploadDir + homepage.aboutGridImage3,
        title: homepage.aboutGridTitle3,
        content: homepage.aboutGridContent3,
      },
      {
        image: apiEndpoint + homepageUploadDir + homepage.aboutGridImage4,
        title: homepage.aboutGridTitle4,
        content: homepage.aboutGridContent4,
      },
    );
    return (
      <div className={s.aboutContainer}>
        <div className={s.aboutBgOne} style={{
          backgroundImage: `url(${bgOne}`
        }}>
          <Container>
            <div className={s.aboutGrid}>
              <div>
                <img src={apiEndpoint + homepageUploadDir + homepage.aboutGridImage2}  />
              </div>
              <div className={s.whiteText}>
                <h1 className={s.aboutHeading}>{homepage.aboutGridTitle1}</h1>
                <p className={s.aboutContent}>{homepage.aboutGridContent1}</p>
              </div>
            </div>
          </Container>
        </div>
        <div className={s.aboutBgTwo}>
          <Container>
            <div className={s.aboutGridTwo}>
              {
                aboutData.map(item => {
                  return (
                    <div>
                      <img src={item.image} className={cx(s.iconImg)} />
                      <h1 className={s.aboutSubHeading}>{item.title}</h1>
                      <p className={s.aboutSubContent}>{item.content}</p>
                    </div>
                  );
                })
              }
            </div>
          </Container>
        </div>
      </div>
    );
  }
}
AboutGrid.contextType = HomeContext
export default withStyles(s)(AboutGrid);
