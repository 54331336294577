import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './SafetyGrid.css';
import bt from '../../../components/commonStyle.css';
import cx from 'classnames';
import { Container, Row, Col } from 'react-bootstrap';
import HomeContext from '../../../routes/context/homeContext'
import { api, homepageUploadDir } from '../../../config'

class SafetyGrid extends React.Component {
  render() {
    const homepage = this.context
    const apiEndpoint = api && api.apiEndpoint
    return (
      <div id="rider-section">
        <Container>
          <div className={cx(s.safetyContainer, 'safetyContainerRTL')}>
            <Row className={s.displayFlex}>
              <Col xl={7} md={7} sm={7} xs={12} className={cx(bt.space4)}>
                <div className={cx(s.signUp, bt.space5)}>
                  <h3>
                    {homepage.safetyGridTitle1}
                  </h3>
                  <p>
                    {homepage.safetyGridContent1}
                  </p>
                </div>
                <div className={cx(bt.paddingTop5, s.responsiveNoPadding)}>
                  <div className={cx(s.displayInlineBlock, s.paddingRight, 'appRightRTL')}>
                    <a href={homepage.safetyGridLink2} target="_blank" >
                      <img src={api.apiEndpoint + homepageUploadDir + homepage.safetyGridImage2} className={s.appImg} />
                    </a>
                  </div>
                  <div className={cx(s.displayInlineBlock, s.paddingLeft, 'paddingLeftRTL')}>
                    <a href={homepage.safetyGridLink1} target="_blank" >
                      <img src={api.apiEndpoint + homepageUploadDir + homepage.safetyGridImage1} className={s.appImg} />
                    </a>
                  </div>
                </div>
              </Col>
              <Col xl={5} md={5} sm={5} xs={12}>
                <div className={s.textCenter}>
                  <img src={api.apiEndpoint + homepageUploadDir + homepage.safetyGridImage3} className={s.phoneImg} />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    );
  }
}
SafetyGrid.contextType = HomeContext
export default withStyles(s, bt)(SafetyGrid);
